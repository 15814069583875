import Bugsnag, { NotifiableError } from '@bugsnag/js'
import debounce from 'lodash/debounce'
import { useRouter } from 'next/router'
import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

import {
  useCreateWantMutation,
  useCreateWantOrderMutation,
  useUpdateWantMutation,
} from 'api/wantsApi'
import { t } from 'localization'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import {
  expirationDaysOptions,
  getButtonText,
  getConversionPrice,
  getExpirationDate,
  getNumberValue,
  getShortcutValues,
  getSizingLabel,
} from 'offers/utils'
import Button from 'shared/components/Button'
import Divider from 'shared/components/Divider'
import { CloseX } from 'shared/components/Icons/SVGIcons/CloseX'
import Image from 'shared/components/Image'
import Spinner from 'shared/components/Spinner'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import useCurrency from 'shared/hooks/useCurrency'
import { useUser } from 'shared/hooks/useUser'
import { colors } from 'shared/lib'
import { sizes } from 'shared/lib/media'
import { setRenderPortal, setRenderSlideOut } from 'store/favoritesSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  resetOfferState,
  setIsOffersModalOpen,
  setIsPlacingOffer,
  setLoginPromptStatus,
  setPlaceOfferAfterLogin,
} from 'store/offersSlice'

type OffersModalContentsProps = {
  handleCloseOffersModal: () => void
}

export const OffersModalContents = forwardRef(
  ({ handleCloseOffersModal }: OffersModalContentsProps, ref: ForwardedRef<HTMLDivElement>) => {
    const TP = 'offers.components.OffersModalContents'
    const { offerModalDetails, promptLogin, placingOffer, placeOfferAfterLogin } = useAppSelector(
      (state) => state.offers,
    )
    const operation = offerModalDetails?.operation || 'create'
    const { availableCurrencies, currencyCode } = useShoppingRegionContext()
    const { selectedCurrency } = useCurrency()
    const { isAuthenticated, currentUser } = useUser()
    const router = useRouter()
    const dispatch = useAppDispatch()
    const [createWant] = useCreateWantMutation()
    const [updateWant] = useUpdateWantMutation()
    const [createWantOrder] = useCreateWantOrderMutation()
    const shortcutValues = getShortcutValues({ offerModalDetails })
    const [offerValue, setOfferValue] = useState('$')
    const [expirationValue, setExpirationValue] = useState(expirationDaysOptions[0])
    const [warningMessage, setWarningMessage] = useState<string | null>(null)
    const isoCode = selectedCurrency?.isoCode || 'USD'
    const { rate = 1, symbol = '$' } =
      availableCurrencies.find((selectedCurrency) => selectedCurrency.isoCode === currencyCode) ??
      {}
    const lowestPrice = Math.floor((offerModalDetails?.lowestPriceCents?.value ?? 0) / 100)
    const minimumOfferPrice = Math.floor((offerModalDetails?.minimumOfferCents ?? 0) / 100)
    const expirationDate = getExpirationDate(expirationValue)
    const sizingLabel = getSizingLabel(offerModalDetails)
    const conversionPrice = getConversionPrice({ offerValue, isoCode, symbol, rate })
    const offerExceedsLowestPrice = getNumberValue(offerValue) > lowestPrice
    const offerBelowMinimumOfferPrice = getNumberValue(offerValue) < minimumOfferPrice
    const buttonText = getButtonText({
      offerValue,
      offerExceedsLowestPrice,
      operation,
    })
    const inputRef = useRef<HTMLInputElement>(null)

    /**
     * Debounce the update of the warning message by two seconds when the user types in the input field
     */
    const updateWarningMessage = useCallback(
      debounce(
        (newValue: string) => {
          const numberValue = getNumberValue(newValue)
          if (numberValue > lowestPrice) {
            setWarningMessage(t(`${TP}.exceedsLowestPrice`, 'Your offer exceeds the Lowest Price'))
          } else if (numberValue < minimumOfferPrice) {
            setWarningMessage(
              t(
                `${TP}.belowMinimumOfferPrice`,
                'Please place an offer above ${minimumOfferPrice}',
                {
                  minimumOfferPrice,
                },
              ),
            )
          } else {
            setWarningMessage(null)
          }
        },
        1000,
        { trailing: true },
      ),
      [lowestPrice, minimumOfferPrice],
    )

    /**
     * Place offer. Invoked when user clicks on the place offer button.
     */
    const placeOffer = useCallback(() => {
      dispatch(setIsPlacingOffer(true))
      createWant({
        offerAmountCents: getNumberValue(offerValue) * 100,
        offerDuration: expirationValue * 24,
        productTemplateId: Number(offerModalDetails?.productTemplateId ?? '0'),
        size: offerModalDetails?.size?.value || 0,
        userId: currentUser?.id || 0,
      })
        .unwrap()
        .then((payloadFromCreateOffer) => {
          sendTrackingEvent('OFFER_PLACE_TAP', {
            product_template_slug: payloadFromCreateOffer.productTemplate.slug,
            size: `${payloadFromCreateOffer.size}`,
            offer_duration: payloadFromCreateOffer.offerDuration,
            offer_amount: `${
              payloadFromCreateOffer.localizedOfferAmountCents.amountUsdCents! / 100
            }`,
            pref_currency_offer_amount: `${
              payloadFromCreateOffer.localizedOfferAmountCents.amount! / 100
            }`,
            pref_currency: payloadFromCreateOffer.localizedOfferAmountCents.currency,
            buy_now: offerModalDetails?.lowestPriceCents?.value || 0,
            want_id: payloadFromCreateOffer.id,
            location: offerModalDetails?.openFrom || '',
          })
          return createWantOrder({ wantId: payloadFromCreateOffer?.id }).unwrap()
        })
        .then((payloadFromWantOrder) => {
          const route = `/checkout/${payloadFromWantOrder?.number}`
          void router.push(route, route, { locale: router.locale })
        })
        .catch((error) => Bugsnag.notify(error as NotifiableError))
    }, [
      dispatch,
      createWant,
      createWantOrder,
      offerValue,
      expirationValue,
      offerModalDetails,
      currentUser,
      router,
    ])

    /**
     * Edit offer. Invoked when user clicks on the edit offer button.
     */
    const editOffer = () => {
      dispatch(setIsPlacingOffer(true))

      updateWant({
        wantId: `${offerModalDetails?.offer?.id || 0}`,
        offerAmountCents: getNumberValue(offerValue) * 100,
        offerDuration: expirationValue * 24,
      })
        .unwrap()
        .then((payloadFromEditOrder) => {
          dispatch(setIsOffersModalOpen({ status: !payloadFromEditOrder.id }))
        })
        .catch((error) => Bugsnag.notify(error as NotifiableError))
    }

    // ******************************************************
    //                EVENT HANDLERS
    // ******************************************************

    const handleCTAOfferClick = async (operation: 'create' | 'edit') => {
      if (!isAuthenticated) {
        return dispatch(setLoginPromptStatus(!isAuthenticated))
      }

      if (operation === 'create') {
        placeOffer()
      } else {
        editOffer()
      }
    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      let currentValue = e.target.value.replace(/\D/g, '')

      updateWarningMessage(currentValue)

      if (currentValue === '$' || !currentValue) {
        return setOfferValue('$')
      }

      if (!currentValue.match(/^\$/)) {
        currentValue = `$${currentValue}`
      }

      if (offerExceedsLowestPrice) {
        if (getNumberValue(currentValue) < lowestPrice) {
          return setOfferValue(currentValue)
        }
        return
      }

      setOfferValue(currentValue)
    }

    const handleCloseSlideOut = () => {
      dispatch(setRenderSlideOut(false))
      setTimeout(() => dispatch(setRenderPortal(false)), 300)
    }

    const handleBuyNewClick = (lowestPriceCents?: number) => {
      const slug = offerModalDetails?.slug
      const size = offerModalDetails?.size?.display
      const hasMatchingSlug = router?.query && router?.query?.slug?.[0] === slug
      const noSizeOrTypeQueryParams = !router?.query.size && !router?.query.type

      if (hasMatchingSlug) {
        const pathname = noSizeOrTypeQueryParams ? router.asPath : `/${slug}`
        router.replace(
          { pathname, query: { size, type: 'offer' } },
          { pathname, query: { size, type: 'offer' } },
          {
            shallow: true,
            locale: router.locale,
          },
        )
      } else {
        router.push(`/${slug}?size=${size}&type=offer`, `/${slug}?size=${size}&type=offer`, {
          locale: router.locale,
        })
      }

      sendTrackingEvent('OFFER_BUY_NOW_TAP', {
        product_template_slug: slug!,
        buy_now_price: lowestPriceCents!,
      })

      handleCloseSlideOut()
      handleCloseOffersModal()
    }

    const handleShortcutClick = (value: {
      display: string
      value: number
      percentageDisplay: string
      percentageValue: number
    }) => {
      const slug = offerModalDetails?.slug

      sendTrackingEvent('OFFER_PRICE_SHORTCUTS_TAP', {
        product_template_slug: slug!,
        price_shortcut: value.value,
        percentage_off_shortcut: value.percentageValue * 100,
      })

      setOfferValue(value.display)
    }

    // Update offer value when editing an offer
    useEffect(() => {
      if (operation === 'edit' && offerModalDetails && offerModalDetails.offer) {
        setOfferValue(`$${(offerModalDetails.offer.offerAmount.amount || 0) / 100}`)
        setExpirationValue(offerModalDetails.offer.offerDuration / 24)
      }
    }, [operation, offerModalDetails])

    // Create offer after login
    useEffect(() => {
      if (placeOfferAfterLogin && !promptLogin && offerModalDetails) {
        placeOffer()
        dispatch(setPlaceOfferAfterLogin(false))
      }
    }, [dispatch, placeOffer, promptLogin, offerModalDetails, placeOfferAfterLogin])

    // Clear warning message immediately when offer value is within allowed range
    useEffect(() => {
      if (!offerExceedsLowestPrice && !offerBelowMinimumOfferPrice) {
        setWarningMessage(null)
      } else {
        updateWarningMessage(offerValue)
      }
    }, [offerExceedsLowestPrice, offerBelowMinimumOfferPrice, offerValue, updateWarningMessage])

    // Focus the input field when the component mounts
    useEffect(() => {
      if (inputRef.current && offerModalDetails?.operation === 'create') {
        inputRef.current.focus()
      }
      // This effect only runs once when the component mounts. No deps needed.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Clean up on unmount
    useEffect(() => {
      return () => {
        updateWarningMessage.cancel() // cancel the debounced function
        dispatch(resetOfferState()) // reset the offer state
      }
      // This s a catch all for the cleanup so no specific deps are needed.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <Wrapper ref={ref} data-qa="OffersModalContentsWrapper">
        <Spinner showSpinner={!!placingOffer}>
          <ModalHeader>
            <BuyNowWrapper>
              <ImageWrapper>
                {offerModalDetails && (
                  <Image
                    data-qa="OffersModalImage"
                    alt={offerModalDetails?.name}
                    src={offerModalDetails?.pictureUrl}
                    width={640}
                    height={640}
                  />
                )}
              </ImageWrapper>
              <BuyNowDetails>
                <BuyNowCTA>
                  <button
                    onClick={() => handleBuyNewClick(offerModalDetails?.lowestPriceCents?.value)}
                    data-qa="OffersModalBuyNowButton"
                  >
                    {t(`${TP}.buyNowCta`, 'Buy Now - {lowestPriceCents}', {
                      lowestPriceCents: `${offerModalDetails?.lowestPriceCents?.display}`,
                    })}
                  </button>
                </BuyNowCTA>
                <span data-qa="OffersModalSizingLabel">{sizingLabel}</span>
              </BuyNowDetails>
            </BuyNowWrapper>
            <CloseButton data-qa="OffersModalCloseButton" onClick={handleCloseOffersModal}>
              <CloseX width="15px" height="15px" />
            </CloseButton>
          </ModalHeader>
          <HeaderDivider />

          <OfferInputLabelWrappper>
            <Label>{t(`${TP}.yourOffer`, 'Your Offer')}</Label>
            {warningMessage && (
              <WarningMessage data-qa="OffersModalExceedsLowestPriceWarning">
                {warningMessage}
              </WarningMessage>
            )}
            {!offerExceedsLowestPrice &&
              !offerBelowMinimumOfferPrice &&
              conversionPrice.display &&
              isoCode !== 'USD' && (
                <OfferValueConversion>{conversionPrice.display}</OfferValueConversion>
              )}
          </OfferInputLabelWrappper>

          <OfferInputWrapper>
            <OfferInput
              aria-label="offer-input"
              data-qa="OffersModalInputValue"
              inputMode="numeric"
              ref={inputRef}
              type="text"
              value={`${offerValue}`}
              onChange={handleOnChange}
              onClick={(e) => {
                if (e.currentTarget.selectionStart || 0 < 1) {
                  e.currentTarget.setSelectionRange(1, 1)
                }
              }}
              onKeyDown={(e) => {
                const position = e.currentTarget.selectionStart || 1
                if (position <= 1 && (e.key === 'Backspace' || e.key === 'ArrowLeft')) {
                  e.preventDefault()
                }
              }}
              onFocus={() => setOfferValue('$')}
            />
          </OfferInputWrapper>

          <Shortcuts>
            {shortcutValues.map((value) => {
              return (
                <ShortcutButton
                  data-qa="OffersModalShortcutButton"
                  key={`${value.display}-${value?.percentageDisplay}`}
                  onClick={() => handleShortcutClick(value)}
                  $isSelected={offerValue === value.display}
                >
                  <ShortcutOfferValue>{value.display}</ShortcutOfferValue>
                  <ShortcutPercentageValue>{value.percentageDisplay}</ShortcutPercentageValue>
                </ShortcutButton>
              )
            })}
          </Shortcuts>

          <ExpirationLabelWrapper>
            <Label>{t(`${TP}.offerExpiration`, 'Offer Expiration')}</Label>
            <span>{expirationDate}</span>
          </ExpirationLabelWrapper>
          <ExpirationDays>
            {expirationDaysOptions.map((expirationDays) => {
              return (
                <ExpirationButton
                  data-qa="OffersModalExpirationButton"
                  key={expirationDays}
                  $isSelected={expirationValue === expirationDays}
                  onClick={() => {
                    if (offerModalDetails) {
                      sendTrackingEvent('OFFER_SET_DURATION_TAP', {
                        product_template_slug: offerModalDetails.slug,
                        size: offerModalDetails.size?.display || '',
                        offer_duration: expirationDays * 24,
                        offer_amount: `${offerValue}`,
                        pref_currency_offer_amount: `${(conversionPrice.price.amount || 0) / 100}`,
                        pref_currency: selectedCurrency?.isoCode || 'USD',
                        location: offerModalDetails.openFrom || '',
                      })
                    }
                    setExpirationValue(expirationDays)
                  }}
                >
                  <div>{`${expirationDays} days`}</div>
                </ExpirationButton>
              )
            })}
          </ExpirationDays>

          <Button
            buttonType="primary2"
            disabled={
              !getNumberValue(offerValue) ||
              offerExceedsLowestPrice ||
              offerBelowMinimumOfferPrice ||
              placingOffer
            }
            $fill
            onClick={() => handleCTAOfferClick(operation)}
            data-qa="OffersModalPlaceOfferButton"
          >
            {buttonText}
          </Button>
          {operation === 'edit' && (
            <AcceptedOfferInstructions>
              {t(
                `${TP}.acceptedOfferInstructions`,
                'You will only be charged if your offer is accepted.',
              )}
            </AcceptedOfferInstructions>
          )}
        </Spinner>
      </Wrapper>
    )
  },
)

OffersModalContents.displayName = 'OffersModalContents'

const Wrapper = styled.div`
  background-color: ${colors.FC2_WHITE};
  padding: 20px 20px 20px 20px;
  height: auto;
  max-width: 430px;
  margin: auto;
  position: relative;

  @media (max-width: ${sizes.large / 16}em) {
    width: 100vw;
    height: 100%;
    padding: 20px;
  }
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const BuyNowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`

const ImageWrapper = styled.div`
  width: 64px;
  height: 64px;
  margin-right: 14px;
`

const BuyNowDetails = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
`

const BuyNowCTA = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  text-decoration: underline;
`

const HeaderDivider = styled(Divider)`
  margin: 0 0 40px 0;
`

const CloseButton = styled.button`
  width: 15px;
  height: 15px;
`

const Label = styled.label`
  text-transform: uppercase;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
`

const OfferInputWrapper = styled.div`
  position: relative;
  width: 100%;
`

const OfferInputLabelWrappper = styled.div`
  display: flex;
  justify-content: space-between;
`

const WarningMessage = styled.div`
  color: #ff0000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

const OfferInput = styled.input`
  appearance: none;
  height: 60px;
  border: 1px solid ${colors.FC2_LIGHTEST_GREY};
  border-radius: 0;
  margin: 15px 0px;
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width: ${sizes.large / 16}em) {
    font-size: 20px;
  }

  &:focus {
    outline: 1px solid black;
    border: none;
    margin: 15px 1px;
  }
`

const OfferValueConversion = styled.div`
  @media (max-width: ${sizes.large / 16}em) {
    right: 16px;
  }
  line-height: normal;
`

const Shortcuts = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 14px;
  margin-bottom: 40px;

  @media (max-width: ${sizes.large / 16}em) {
    gap: 11px;
  }
`

const ShortcutOfferValue = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2px;
`
const ShortcutPercentageValue = styled.div`
  color: ${colors.FC2_GREY};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const ShortcutButton = styled.button<{ $isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 122px;
  height: 60px;
  border: 1.7px solid
    ${({ $isSelected }) => ($isSelected ? colors.FC2_BLACK : colors.FC2_LIGHTEST_GREY)};

  @media (max-width: ${sizes.large / 16}em) {
    min-width: 105px;
    height: 60px;
  }
`

const ExpirationLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const ExpirationDays = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  margin: 16px 0px 40px 0px;

  @media (max-width: ${sizes.large / 16}em) {
    gap: 10px;
  }
`

const ExpirationButton = styled.button<{ $isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 36px;
  border: 1.7px solid
    ${({ $isSelected }) => ($isSelected ? colors.FC2_BLACK : colors.FC2_LIGHTEST_GREY)};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;

  @media (max-width: ${sizes.large / 16}em) {
    min-width: 77px;
  }
`

const AcceptedOfferInstructions = styled.div`
  text-align: center;
  width: 352px;
  margin: 20px auto auto auto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: ${sizes.large / 16}em) {
    font-size: 11px;
    width: 100%;
  }
`
